<template>
  <v-dialog
    v-if="entity"
    v-model="open"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
    scrollable
  >
    <v-card>
      <v-toolbar flat>
        <v-btn icon :loading="processing" :disabled="processing" @click="close">
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
        <v-toolbar-title>{{
          $t("model.role.role") +
            " (" +
            (!editMode ? $t("base.addNew") : $t("base.edit")) +
            ")"
        }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <validation-observer ref="form" v-slot="{ handleSubmit, reset }">
          <form @submit.prevent="handleSubmit(onSave)" @reset.prevent="reset">
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-row>
                    <v-col cols="12" sm="5">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('model.role.name')"
                        rules="required|max:100"
                      >
                        <v-text-field
                          v-model="entity.name"
                          prepend-icon="mdi-pencil"
                          :error-messages="errors"
                          :placeholder="$t('model.role.name')"
                          :label="$t('model.role.name')"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" sm="5">
                      <v-textarea
                        v-model="entity.description"
                        clearable
                        :rows="1"
                        prepend-icon="mdi-pencil"
                        :placeholder="$t('model.role.description')"
                        :label="$t('model.role.description')"
                      />
                    </v-col>

                    <v-col cols="12" sm="2">
                      <v-switch
                        v-model="entity.status"
                        :label="
                          entity.status ? $t('base.enable') : $t('base.disable')
                        "
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12">
                  <!-- <v-col>
                    <p class="caption">
                      <v-icon class="mr-5">mdi-key</v-icon
                      >{{ $t("model.permission.permission") }}
                    </p>
                    <v-text-field
                      :label="$t('base.search')"
                      v-model="searchTxt"
                    ></v-text-field>
                  </v-col> -->

                  <v-row>
                    <v-col>
                      <v-card>
                        <v-toolbar color="primary" dark flat>
                          <v-icon>mdi-key</v-icon>
                          <v-toolbar-title class="ml-3">{{
                            $t("model.permission.permission")
                          }}</v-toolbar-title>
                        </v-toolbar>

                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="searchTxt"
                              :label="$t('base.search')"
                              filled
                              rounded
                              dense
                              hide-details
                              clearable
                              clear-icon="mdi-close-circle"
                            />

                            <v-card-text
                              style="max-height: 400px"
                              class="overflow-y-auto"
                            >
                              <v-treeview
                                v-model="entity.selectedPermissions"
                                :items="permissions"
                                :search="searchTxt"
                                :filter="filter"
                                item-key="id"
                                item-text="description"
                                :selection-type="'leaf'"
                                selectable
                                :return-object="false"
                                open-all
                                hoverable
                              />
                            </v-card-text>
                          </v-col>

                          <v-divider vertical />

                          <v-col cols="12" md="6">
                            <v-card-text
                              style="max-height: 400px"
                              class="overflow-y-auto"
                            >
                              <div
                                v-if="entity.selectedPermissions.length === 0"
                                key="title"
                                class="title font-weight-light grey--text pa-4 text-center"
                              >
                                Select permissions for this role.
                              </div>

                              <v-scroll-x-transition group hide-on-leave>
                                <v-chip
                                  v-for="(permssion,
                                  index) in entity.selectedPermissions"
                                  :key="
                                    `role-form-permission-selected-${index}`
                                  "
                                  class="ma-2"
                                  close
                                  @click:close="removePermission(index)"
                                >
                                  {{ findDisplayPermissionById(permssion) }}
                                </v-chip>
                              </v-scroll-x-transition>
                            </v-card-text>
                          </v-col>
                        </v-row>

                        <v-divider />

                        <v-card-actions>
                          <v-spacer />
                          <v-btn text @click="entity.selectedPermissions = []">
                            <v-icon>mdi-delete</v-icon>
                            {{ $t("base.clear") }}
                          </v-btn>
                          <!-- <v-btn
                            class="white--text"
                            color="green darken-1"
                            depressed
                          >
                            Save
                            <v-icon right>mdi-content-save</v-icon>
                          </v-btn> -->
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="mt-6" align="center">
                  <v-btn :disabled="processing" text @click="close">
                    {{ $t("base.cancel") }}
                  </v-btn>
                  <v-btn
                    type="submit"
                    color="primary"
                    text
                    :disabled="processing"
                  >
                    <v-icon>mdi-lead-pencil</v-icon> {{ $t("base.save") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
/* eslint-disable vue/require-default-prop */
import { defineComponent, reactive, ref, computed } from "@vue/composition-api";
export default defineComponent({
  props: {
    value: null,
    open: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    permissions: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const searchTxt = ref("");
    const entity = reactive(props.value);
    const close = () => {
      emit("close");
    };
    const onSave = () => {
      emit("save", entity);
    };

    const findDisplayPermissionById = id => {
      // const item = props.permissions.find(c => c.id == id);
      const item = props.permissions.find(c =>
        c.children.find(p => p.id == id)
      );
      return item && item.children.length > 0
        ? item.children[0].description
        : item.children[0].name;
    };

    const caseSensitive = ref(false);
    const filter = computed(() => {
      return caseSensitive.value
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    });

    const removePermission = index => {
      entity.selectedPermissions.splice(index, 1);
    };

    return {
      searchTxt,
      entity,
      close,
      onSave,
      // selectedPermissions,
      // filterPermissions,
      findDisplayPermissionById,
      filter,
      removePermission
    };
  }
});
</script>
